@font-face {
  font-family: Poppins_Black;
  src: url("./assets/fonts/Poppins-Black.ttf");
}
@font-face {
  font-family: Poppins_Bold;
  src: url("./assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: Poppins_Medium;
  src: url("./assets/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins_Regular;
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: SourceSans3_Black;
  src: url("./assets/fonts/SourceSans3-Black.ttf");
}
@font-face {
  font-family: SourceSans3_Bold;
  src: url("./assets/fonts/SourceSans3-Bold.ttf");
}
@font-face {
  font-family: SourceSans3_Medium;
  src: url("./assets/fonts/SourceSans3-Medium.ttf");
}
@font-face {
  font-family: SourceSans3_Regular;
  src: url("./assets/fonts/SourceSans3-Regular.ttf");
}

@font-face {
  font-family: jejuhallasan-regular;
  src: url("./assets/fonts/JejuHallasan-Regular.ttf");
}

body {
  margin: 0;
  font-family: Poppins_Regular, Poppins_Black, Poppins_Bold, Poppins_Medium,
    SourceSans3_Regular, SourceSans3_Black, SourceSans3_Bold, SourceSans3_Medium,
    -apple-system, BlinkMacSystemFont, jejuhallasan-regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#282945";
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  /* border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0; */
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border-top: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0; */
}

.MuiTableRow-head th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  /* border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0; */
}

.MuiTableRow-head th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border-top: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0; */
}

.wrapper .iconWrapper {
  list-style: none;
}

.wrapper .iconWrapper .iconList {
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 50%;
  /* border: 5px solid #d8e2dc; */
  transition: all 0.5s ease;
}

.wrapper .iconWrapper .iconList .icon {
  color: #d8e2dc;
  /* margin-top: 20px; */
  transition: all 0.5s ease;
}

.wrapper .iconWrapper .iconList:hover.facebook {
  /* border: 5px solid #3b5998; */
  /* box-shadow: 0 0 15px #3b5998; */
  transition: all 0.5s ease;
}

.wrapper .iconWrapper .iconList:hover .facebookIcon {
  color: #3b5998;
  text-shadow: 0 0 15px #3b5998;
  transition: all 0.5s ease;
}

.wrapper .iconWrapper .iconList:hover.twitter {
  /* border: 5px solid #00aced; */
  /* box-shadow: 0 0 15px #00aced; */
  transition: all 0.5s ease;
}

.wrapper .iconWrapper .iconList:hover .twitterIcon {
  color: #00aced;
  text-shadow: 0 0 15px #00aced;
  transition: all 0.5s ease;
}

.wrapper .iconWrapper .iconList:hover.instagram {
  /* border: 5px solid #bc2a8d; */
  /* box-shadow: 0 0 15px #bc2a8d; */
  transition: all 0.5s ease;
}

.wrapper .iconWrapper .iconList:hover .instagramIcon {
  color: #bc2a8d;
  text-shadow: 0 0 15px #bc2a8d;
  transition: all 0.5s ease;
}

@media screen and (max-width: 640px) {
  .wrapper {
    width: 350px;
  }
  .wrapper .iconWrapper .iconList {
    margin-top: 10px;
  }
}

@media screen and (max-width: 340px) {
  .wrapper {
    width: 150px;
  }
  .wrapper .iconWrapper .iconList {
    margin: 15px;
  }
}

.btnWrapper {
  display: flex;
  justify-content: center;
}

.custom-pie-chart svg rect {
  fill: transparent !important;
}

.chart-container {
  border: 1px solid rgba(255, 255, 255, 0.21);
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.3) !important;
}

.custom-pie-chart path {
  stroke: #ffffff75 !important;
  stroke-width: 1px;
}

.custom-pie-chart text {
  font-family: Poppins_Medium;
  font-weight: 500;
  font-size: 16px;
  /* fill: white; */
}
